<template>
  <div class="wrapper">
    <main>
      <section class="demo">
        <div class="container">
          <div class="demo-title">
            <b
              >TABLEAUX <br />
              SYNOPTIQUES</b
            >
          </div>
          <div class="demo-main">
            <div class="demo-image d-flex justify-content-center">
              <a
                href="https://drive.google.com/file/d/1PKyY6TFfjViddta4NwOwbJgK19xB6_yg/view?usp=share_link"
                target="_blank"
              >
                <img src="/tableaux_synoptiques.png" />
              </a>
            </div>
          </div>
          <!-- <div class="demo-image">
              <img src="/affiche.png" alt="demo image" />
            </div> -->
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: " ",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body,
html {
  height: 100%;
}
.demo {
  padding: 3rem 0rem;
  background-color: #185c8c;
}
.demo .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* gap: 1rem; */
  align-items: center;
  color: #ffffff;
}
.demo-title {
  position: relative;
  right: 100px;
  flex: 1;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: center;
  font-size: 3rem;
  transform: rotate(180deg);
}
.demo-main {
  /* flex: 4;
    color: #000;
    background-color: #ffffff;
    text-align: justify;
    border: 6px solid #f89434;
    border-radius: 2rem;
    padding: 2rem;
    width: 400px;
     position: relative;
    left: 50px; */
}
.demo-image {
  flex: 2;
  display: inline-block;
}

.demo-image img {
  height: 600px;
  object-fit: contain;
  position: relative;
  left: 100px;

  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.zoom :hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.line2 {
  display: none;
}
.line1 {
  position: relative;
  left: 200px;
}
.parent:hover .line1 {
  display: none;
}
.parent:hover .line2 {
  display: inline;
}
.centre {
  position: relative;
  left: 140px;
}
.centre1 {
  position: relative;
  left: 170px;
}
.centre2 {
  position: relative;
  left: 130px;
}
@media (max-width: 500px) {
  .demo .container {
    flex-direction: column;
  }
  .demo-title {
    flex: 1;
    position: relative;
    top: -30px;
    right: 0px;
    writing-mode: horizontal-tb;
    text-align: center;
    font-size: 3rem;
    transform: rotate(0deg);
  }
  .demo-main {
    flex: 4;
    color: #000;
    /* background-color: #ffffff; */
    text-align: justify;
    /* border: 6px solid #f89434; */
    border-radius: 2rem;
    padding: 2rem;
    position: relative;
    left: 0px;
  }
  .demo-image img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    position: relative;
    left: 10px;
  }
  .centre {
    position: relative;
    left: 150px;
  }
}
</style>
