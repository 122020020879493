<template>
  <div class="wrapper">
    <main>
      <section class="demo">
        <div class="container">
          <div class="demo-title">
            <b>PRÉSENTATION<br />DE L'ÉVÉNEMENT</b>
          </div>
          <div class="demo-main">
            <p>
              La Morocco Sim, société marocaine de simulation en santé,
              organise, en partenariat avec l’Université Hassan II, la Faculté
              de Médecine et de Pharmacie de Casablanca et le CHU Ibn Rochd de
              Casablanca, la deuxième édition du Congrès "Healthcare training &
              innovation conference", du <b>23 au 25 février 2023</b>, à la
              Faculté de Médecine et de Pharmacie de Casablanca.
            </p>
            Après le succès phénoménal de la 1ère édition organisée à Tanger,
            cette 2ème édition vient consolider les acquis de la 1ère, à savoir
            des tracks classiques liés à la simulation procédurale, la
            simulation avec retour haptique, la simulation haute-fidélité, la
            simulation inter-professionnelle, la simulation par les ultrasons,
            les serious game, la ludification pédagogique, la réalité virtuelle
            et augmentée, l’intelligence artificielle et la pédagogie, mais
            aussi proposer des nouveautés quant au rajout de 4 tracks
            supplémentaires, à savoir celui de la radiologie, la pédiatrie, la
            médecine dentaire et de la pharmacie.
            <div class="parent">
              <div class="line1" style="color:#3e90c0">Lire la suite</div>
              <div class="line2">
                Par ailleurs, cette édition vient renforcer aussi les aspects
                liés à l’évaluation, la recherche et l’innovation, avec un
                public cible très large médecins, médecins dentistes,
                pharmaciens, infirmiers, étudiants, ingénieurs, managers et
                biomédicaux. Cette édition enfin s’inscrit dans la vision
                nationale de l’optimisation de la formation dans les sciences de
                la santé et de la réforme des études médicales.<br />Nous vous
                attendons donc toutes et tous nombreux à accepter notre
                inivitation pour participer aux travaux de ce congrès et de nous
                honorer également par votre présence à la session d’inauguration
                officielle, prévue le vendredi 24 février 2023 à 18H00 à
                l’amphithéâtre Ibn Nafis, à la faculté de Médecine et de
                Pharmacie de Casablanca.
                <p>
                  Nous sommes très confiants en la contribution de tous nos
                  partenaires universitaires, hospitaliers et commerciaux à la
                  réussite annoncée de cette édition. Nous sommes impatients de
                  pouvoir vous accueillir selon les bonnes vieilles traditions
                  marocaines !
                </p>
                <div class="centre">Pour la Morocco sim</div>
                <div class="centre1"><b>Le Président</b></div>
                <div class="centre2"><b>Pr. Mohammed Mouhaoui</b></div>
              </div>
            </div>
          </div>
          <div class="demo-image">
            <img src="/affiche.png" alt="demo image" />
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: " ",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body,
html {
  height: 100%;
}

.demo {
  padding: 3rem 0rem;
  background-color: #185c8c;
}

.demo .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* gap: 1rem; */
  align-items: center;
  color: #ffffff;
}

.demo-title {
  position: relative;
  right: 100px;
  flex: 1;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: center;
  font-size: 3rem;
  transform: rotate(180deg);
}

.demo-main {
  flex: 4;
  color: #000;
  background-color: #ffffff;
  text-align: justify;
  border: 6px solid #f89434;
  border-radius: 2rem;
  padding: 2rem;
  width: 400px;
  position: relative;
  left: 50px;
}

.demo-image {
  flex: 2;
  display: inline-block;
}

.demo-image img {
  height: 600px;
  object-fit: contain;
  position: relative;
  left: 100px;
}

.line2 {
  display: none;
}

.line1 {
  position: relative;
  left: 200px;
}

.parent:hover .line1 {
  display: none;
}

.parent:hover .line2 {
  display: inline;
}

.centre {
  position: relative;
  left: 140px;
}

.centre1 {
  position: relative;
  left: 170px;
}

.centre2 {
  position: relative;
  left: 130px;
}

@media (max-width: 500px) {
  .demo .container {
    flex-direction: column;
  }

  .demo-title {
    flex: 1;
    position: relative;
    top: -30px;
    right: 0px;
    writing-mode: horizontal-tb;
    text-align: center;
    font-size: 3rem;
    transform: rotate(0deg);
  }

  .demo-main {
    flex: 4;
    color: #000;
    background-color: #ffffff;
    text-align: justify;
    border: 6px solid #f89434;
    border-radius: 2rem;
    padding: 2rem;
    position: relative;
    left: 0px;
  }

  .demo-image img {
    height: 600px;
    object-fit: contain;
    position: relative;
    left: 10px;
  }

  .centre {
    position: relative;
    left: 150px;
  }
}
</style>
