<template>
<div class="header" >
    <!-- The header content -->
<div class="head1">
  <p>
    <img src="/d.png"
        gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
        aspect-ratio="1.6" class="img-responsive center">  </p>
</div>
  </div>
</template>

<script>

  export default {
    name: 'accueil',

    mounted() {
    window.setInterval(() => {
        this.now = Math.trunc((new Date()).getTime() / 1000);
    },1000);
    
    // Get the modal
    var modal = document.getElementById("myModal");

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");
    img.onclick = function(){
      modal.style.display = "block";
      modalImg.src = this.src;
      captionText.innerHTML = this.alt;
    }

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function() { 
      modal.style.display = "none";
    }
  },
  data() {
    return {
      now: Math.trunc((new Date()).getTime() / 1000),
       date:"2021-12-25 00:00:00",
    }
  },
  computed: {
    dateInMilliseconds() {
      return Math.trunc(Date.parse(this.date) / 1000)
    },
    seconds() {
      return (this.dateInMilliseconds - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
    },
    days() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
    }
  },
  methods:{
    click: function() {
        this.$refs.inscriComponent.setValue();

        this.$gtag.event('form d\'inscription', {
        'event_category': 'fr',
        'event_label': 'dernier click',
        'value': 1
      })
    },
  }
  }
</script>
<style >
.head1{

}
.img{
  width: 150px;
}
</style>
