<template>
    <div>
        <div class="container">
            <div class="row">

                <!-- <b>
                    <div style="color:#3e90c0" class="centre">BEST OF</div>
                    <div style="color:#3e90c0" class="centre1">HTIC 2023<br></div>
                    <div style="color:#3e90c0" class="centre2"> <br> </div>
                </b> -->

                <v-col class="ytb" align="center">
                    <div class="iframe-container p-1 " data-aos-once="true" data-aos="fade-up" data-aos-duration="1500">

                        <iframe class="responsive-iframe p-1" src="https://www.youtube.com/embed/gZPJ8nOISqs"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen></iframe>
                    </div>
                </v-col>
            </div>
        </div>
        <!-- <v-container class="AB" grid-list-xs>
      <v-col align="center">
            <h1 style="color:#3e90c0"  class="compHead">
                A PROPOS DE HTIC 2022
            </h1>
            <v-divider></v-divider>
            </v-col>
			<v-row>
        <v-col align="center">
        <h1 style="color:#3e90c0"  class="compHead">
                A PROPOS DE HTIC 2022
            </h1>
			</v-col>
			<v-col align="center">
				<div  class="iframe-container p-1 " data-aos-once="true" data-aos="fade-up" data-aos-duration="1500">
					<iframe class="responsive-iframe p-1"   src="https://www.youtube.com/embed/tISdunVOb3w"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
			</v-col>
			</v-row>
		</v-container> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
}
</script>
<style >
.ytb {
    position: relative;
    bottom: 50px;

}

.responsive-iframe {
    border-radius: 5%;
    background-color: #ffffff;
}

.iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-top: -10px;
    height: 100px;
    /* padding-top: 56.25%; */
}

.responsive-iframe {
    /* position: absolute; */
    top: 0;
    left: 500px;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;

}

.row {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.column1 {
    /* flex: 0; */
    resize: both;
    height: 400px;
    width: 500px;
    position: relative;
    top: 200px;
    left: -50px;
    font-size: 3rem;
}

.centre {
    position: relative;
    left: 120px;
}

.centre1 {
    position: relative;
    left: 150px;
}

.centre2 {
    position: relative;
    left: 180px;
}

@media (max-width: 500px) {
    .column1 {
        resize: both;
        height: 350px;
        width: 500px;
        position: relative;
        top: 100px;
        left: -50px;
        font-size: 3rem;
    }

    .iframe-container {
        position: relative;
        left: -40px;
    }

    .responsive-iframe {
        width: 200px;
    }


    .ytb {
        position: relative;
        left: 40px;
        resize: both;

    }

}
</style>