

<template>
    <div class="wrapper">
        <main>
            <section class="demo">
                <img src="/sponsors htic-01.jpg" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                    aspect-ratio="1." class="img-responsive center">
            </section>
        </main>
    </div>
</template>

<script>
export default {
    name: " ",
    props: {
        msg: String,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body,
html {
    height: 100%;
}

.demo {
    padding: 3rem 0rem;
    background-color: #ffffff;
    resize: both;
    height: 700px;
    position: relative;
    left: 140px;
    top: 0px;
}

.demo .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* gap: 1rem; */
    align-items: center;
    color: #ffffff;
}

.demo-title {
    /* text-transform: uppercase; */
    position: relative;
    right: 100px;
    /* flex: 1; */
    color: #000000;
    /* writing-mode: vertical-lr; */
    /* text-orientation: mixed; */
    /* text-align: center; */
    font-size: 2.5rem;
    height: 500px;
    width: 500px;

    /* transform: rotate(180deg); */
}

.demo-title .title1 {
    color: #000000;
    font-size: 1.8rem;
    height: 500px;
    width: 500px;
    /* font-style: italic; */
}

.demo-image {
    position: relative;
    flex: 2;
    display: inline-block;
}

.demo-image1 {
    position: relative;
    flex: 2;
    display: inline-block;
    resize: both;
    width: 250px;
    height: 200px;
}

.demo-image img {
    height: 600px;
    object-fit: contain;
    position: relative;
    left: 100px;
}

.line2 {
    display: none;
}

.line1 {
    position: relative;
    left: 200px;
}

.parent:hover .line1 {
    display: none;
}

.parent:hover .line2 {
    display: inline;
}

.centre {
    position: relative;
    left: 140px;
}

.centre1 {
    position: relative;
    left: 170px;
}

.centre2 {
    position: relative;
    left: 130px;
}

@media (max-width: 500px) {
    .demo {
        padding: 3rem 0rem;
        background-color: #ffffff;
        resize: both;
        height: 300px;
        position: relative;
        left: 0px;
        top: 0px;
    }

    .demo .container {
        flex-direction: column;
    }

    .demo-title {
        flex: 1;
        position: relative;
        top: -30px;
        right: 00px;
        writing-mode: horizontal-tb;
        text-align: center;
        font-size: 2.5rem;
        transform: rotate(0deg);
    }

    .demo-image img {
        height: 600px;
        object-fit: contain;
        position: relative;
        left: 10px;
    }

    .demo-image {
        height: 400px;
        object-fit: contain;
        position: relative;
        left: -5px;
        top: -200px;
    }

    .centre {
        position: relative;
        left: 150px;
    }

    .title1 {
        width: 100%;
    }
}
</style>
